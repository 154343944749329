<template>
  <div v-loading="loaders.dynamicSectionSubCategories" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of {{ dynamicContentSection.name }} Sub Categories</h1>
      <div class="d-flex justify-content-end">
        <base-button class="btn-secondary-action title-btn-padding button-font" @click="toggleModal('Save')">Add New</base-button>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.dynamicSectionSubCategories"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.dynamicSectionSubCategories"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.dynamicSectionSubCategories"
        >Deactivate
        </base-button>
      </div>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        width="100%"
        ref="multipleSelectionTable"
        :data="response.dynamicSectionSubCategories"
        @selection-change="dynamicSectionSubCategoriesMultiSelect"
      >
        <el-table-column prop="selected" type="selection" min-width="130px">
        </el-table-column>
        <el-table-column label="Sub Category" prop="name" min-width="130px">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Category" prop="name" min-width="130px">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">
              {{ (scope.row.dynamic_section_category) ? scope.row.dynamic_section_category.name : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="status" min-width="130px">
          <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS">
          <div slot-scope="{ $index, row }" class="d-flex">
            <img
              class="pointer"
              @click="toggleModal('Update' , row)"
              src="/img/icons/buttons/edit.svg"
              alt="Edit"
            />
            <img
              class="pointer ml-2"
              @click="handleDelete(row)"
              src="/img/icons/buttons/delete.svg"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
      <div class="col-12 d-flex justify-content-end flex-wrap">
        <custom-pagination
          class="mt-4 pagination-no-border float-right"
          v-model="request.pagination.page"
          :per-page="request.pagination.perPage"
          :total="request.pagination.total"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeDynamicSectionSubCategory"
        @onRemoveMultiple="deleteDynamicSectionSubCategories"
      />
      <el-dialog
        :title="modal.title"
        :visible.sync="modals.dynamicSectionSubCategoryModal"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <el-form role="form" :model="dynamicSectionSubCategory" ref="formDynamicSectionSubCategory"
                 :name="modal.formType">
          <div class="mt--4">
            <label for="">Name*</label>
            <el-form-item prop="name" :rules="rules.name">
              <el-input
                class=""
                name="Sub Category Name"
                v-model="dynamicSectionSubCategory.name"
                placeholder="Sub Category Name">
              </el-input>
            </el-form-item>
            <label for="">Category*</label>
            <el-form-item prop="dynamicSectionCategory" :rules="rules.dynamicSectionCategory">
              <el-select
                class="w-100"
                label="Category"
                :rules="{required: true}"
                v-model="dynamicSectionSubCategory.dynamicSectionCategory">
                <el-option
                  v-for="option in response.dynamicSectionCategories"
                  class="select-danger"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id">
                </el-option>
              </el-select>
            </el-form-item>
            <label for="">Status*</label>
            <el-form-item prop="status" :rules="rules.status">
              <el-select
                class="w-100"
                v-model="dynamicSectionSubCategory.status">
                <el-option
                  v-for="option in dropdowns.status"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="text-right mt-4">
            <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
            <base-button class="btn-primary-action pl-5 pr-5 br-4" @click="onFormSubmit('formDynamicSectionSubCategory')"
                         type="success">{{ modal.buttonText }}
            </base-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import BackButton from "@/components/Router/BackButton";
import CustomPagination from "../../../../Components/Pagination/CustomPagination";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

const RemoveModal = () => import(/* webpackChunkName: "dynamicSectionSubCategoriesRemoveModal" */ "@/components/Modals/RemoveModal.vue");

export default {
  name: "DynamicContentSectionSubCategory",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal, BackButton, CustomPagination
  },
  data() {
    return {
      dynamicContentSection: {
        name: this.$route.params.sectionName ?? '',
        id: this.$route.params.sectionId ?? 0,
      },
      loaders: {
        dynamicSectionSubCategories: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        dynamicSectionCategories: [],
        dynamicSectionSubCategories: [],
      },
      modals: {
        dynamicSectionSubCategoryModal: false,
      },
      modal: {
        title: 'Add Sub Category',
        buttonText: 'Save',
        formType: 'Save',
      },
      dynamicSectionSubCategory: {
        id: '',
        name: '',
        status: 1,
        dynamicSectionCategory: '',
      },
      selectedDynamicSectionSubCategory: '',
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      rules: {
        name: [
          {required: true, message: 'Please input Sub Category Name', trigger: 'blur'},
          {min: 3, max: 55, message: 'Length should be 3 to 55', trigger: 'blur'}
        ],
        status: [
          {required: true, message: 'Please select status', trigger: 'change'},
        ],
        dynamicSectionCategory: [
          {required: true, message: 'Please select Category', trigger: 'change'},
        ],
      },
      request: {
        pagination: {
          page: 1,
          currentPage: 1,
          perPage: 20,
          total: 1,
        }
      },
    };
  },
  mounted() {
    //Condition to check if params are not passed from previous route then call the API
    if (this.dynamicContentSection.name == '') {
      this.getDynamicContentSection()
    }
    this.fetchDynamicSectionSubCategories()
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Get request dynamic content section detail
     */
    getDynamicContentSection() {
      let vm = this
      vm.loaders.dynamicSectionCategories = true
      const params = {id: vm.dynamicContentSection.id}

      vm.$store.dispatch('dynamicContentSectionModule/_getRequestDynamicContentSection', params)
        .then(response => {
          vm.dynamicContentSection = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.dynamicSectionCategories = false
        })
    },

    /**
     * Get all the dynamic section sub categories
     */
    fetchDynamicSectionSubCategories() {
      let vm = this
      vm.loaders.dynamicSectionSubCategories = true
      const params = {
        sectionId: vm.dynamicContentSection.id,
        page: vm.request.pagination.page
      }

      vm.$store.dispatch('dynamicContentSectionModule/_fetchAllDynamicSectionSubCategories', params)
        .then((response) => {
          vm.response.dynamicSectionSubCategories = response.data.data.data
          vm.request.pagination.page = response.data.data.current_page
          vm.request.pagination.perPage = response.data.data.per_page
          vm.request.pagination.total = response.data.data.total
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name,
            message: message
          })
        })
        .finally(() => vm.loaders.dynamicSectionSubCategories = false)
    },

    /**
     * Handle activate button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle multi-select event
     * @param selectedRows
     */
    dynamicSectionSubCategoriesMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Handle status update request
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.dynamicSectionSubCategories = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('dynamicContentSectionModule/_updateStatusDynamicSectionSubCategoryItem', payload)
        .then(response => {
          vm.removeId = []
          vm.fetchDynamicSectionSubCategories()
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' sub category status updated successfully.'
          })
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Section Sub Category Item',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.dynamicSectionSubCategories = false
        })
    },

    /**
     * Handle delete event
     * @param row
     */
    handleDelete(row) {
      this.promptRemove(row)
    },
    /**
     * Prompt the single remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this ' + this.dynamicContentSection.name + ' Sub Category.\n' +
        'Deleting Sub Category will remove all ' + this.dynamicContentSection.name + ' info associated with it.';
      this.removeId = row.id;
    },
    /**
     * Prompt the Multi-remove modal
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these ' + this.dynamicContentSection.name + ' Sub Categories.\n' +
        'Deleting Sub Category will remove all ' + this.dynamicContentSection.name + ' info associated with it.';
      this.removeId = this.extractSelectedIds;
    },

    /**
     * Handle remove dynamic section category event
     * @param id
     */
    removeDynamicSectionSubCategory(id) {
      this.deleteRequest(id)
    },

    /**
     * handle delete dynamic section sub categories event
     */
    deleteDynamicSectionSubCategories() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Delete Dynamic Section Sub Category
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.dynamicSectionSubCategories = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}

      vm.$store.dispatch('dynamicContentSectionModule/_deleteDynamicSectionSubCategoryItem', payload)
        .then(response => {
          vm.removeId = []
          vm.fetchDynamicSectionSubCategories()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' sub category deleted successfully.'
          })
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Section Sub Category Item',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.dynamicSectionSubCategories = false
        })
    },

    /**
     * Toggle Modal event
     * @param modalType
     * @param row
     */
    toggleModal(modalType, row) {
      let vm = this
      if (modalType === 'Save') {
        vm.modal.title = 'Add ' + vm.dynamicContentSection.name + ' Sub Category'
        vm.modal.buttonText = 'Save'
        vm.modal.formType = 'Save'
      } else if (modalType === 'Update') {
        vm.modal.title = 'Update ' + vm.dynamicContentSection.name + ' Sub Category'
        vm.modal.buttonText = 'Update'
        vm.modal.formType = 'Update'
        vm.selectedDynamicSectionSubCategory = row.id
        vm.fetchDynamicSectionSubCategory()
      }
      vm.fetchDynamicSectionCategories()
      vm.modals.dynamicSectionSubCategoryModal = !this.modals.dynamicSectionSubCategoryModal
    },

    /**
     * Get the requested dynamic section sub category
     */
    fetchDynamicSectionSubCategory() {
      let vm = this
      const payload = {
        id: vm.selectedDynamicSectionSubCategory,
        sectionId: vm.dynamicContentSection.id
      }

      vm.$store.dispatch('dynamicContentSectionModule/_showDynamicSectionSubCategoryItem', payload)
        .then((response) => {
          vm.dynamicSectionSubCategory.id = response.data.data.id
          vm.dynamicSectionSubCategory.name = response.data.data.name
          vm.dynamicSectionSubCategory.status = response.data.data.status
          vm.dynamicSectionSubCategory.dynamicSectionCategory = response.data.data.dynamic_section_category_id
        })
        .catch((error) => console.error(error.response))
    },

    /**
     * Fetch the dynamic section categories
     */
    fetchDynamicSectionCategories() {
      let vm = this
      vm.loaders.dynamicSectionSubCategories = true
      const params = {
        sectionId: vm.dynamicContentSection.id
      }

      vm.$store.dispatch('dynamicContentSectionModule/_getAllDynamicSectionCategories', params)
        .then((response) => {
          vm.response.dynamicSectionCategories = response.data.data
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name + ' Category',
            message: message
          })
        })
        .finally(() => vm.loaders.dynamicSectionSubCategories = false)
    },

    /**
     * Form submit event
     * @param formName
     * @returns {Promise<void>}
     */
    async onFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$refs.formDynamicSectionSubCategory.$attrs.name === 'Save') {
            this.storeDynamicSectionSubCategory()
          } else if (this.$refs.formDynamicSectionSubCategory.$attrs.name === 'Update') {
            this.updateDynamicSectionSubCategory()
          }
        } else {
          this.$notify.error({
            title: 'Validation Error',
            message: 'Please check values in the form'
          })
          return false
        }
      });
    },

    /**
     * Store Dynamic Section Sub Category
     */
    storeDynamicSectionSubCategory() {
      let vm = this
      vm.loaders.dynamicSectionSubCategories = true
      vm.dynamicSectionSubCategory.sectionId = vm.dynamicContentSection.id

      vm.$store.dispatch('dynamicContentSectionModule/_storeDynamicSectionSubCategoryItem', vm.dynamicSectionSubCategory)
        .then(response => {
          vm.handleClose()
          this.fetchDynamicSectionSubCategories()
          this.modals.dynamicSectionSubCategoryModal = false
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' sub category added successfully.'
          })
        })
        .catch(error => {
          const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name + ' Sub Category',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          this.loaders.dynamicSectionSubCategories = false
        })
    },

    /**
     * Update Dynamic Section Sub Category
     */
    updateDynamicSectionSubCategory() {
      let vm = this
      vm.loaders.dynamicSectionSubCategories = true
      vm.dynamicSectionSubCategory.sectionId = vm.dynamicContentSection.id

      vm.$store.dispatch('dynamicContentSectionModule/_updateDynamicSectionSubCategoryItem', vm.dynamicSectionSubCategory)
        .then((response) => {
          vm.handleClose()
          this.fetchDynamicSectionSubCategories()
          this.modals.dynamicSectionSubCategoryModal = false
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' sub category updated successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name + ' Sub Category',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          this.loaders.dynamicSectionSubCategories = false
        })
    },

    /**
     * Handle close event
     */
    handleClose() {
      this.clearDynamicSectionCategoryForm();
      this.$refs.formDynamicSectionSubCategory.resetFields();
      this.modals.dynamicSectionSubCategoryModal = false;
    },

    /**
     * Clear form fields
     */
    clearDynamicSectionCategoryForm() {
      this.dynamicSectionSubCategory.id = ''
      this.dynamicSectionSubCategory.name = ''
      this.dynamicSectionSubCategory.status = 1
      this.dynamicSectionSubCategory.dynamicSectionCategory = ''

      this.modal.title = ''
      this.modal.buttonText = ''
      this.modal.formType = ''
      this.selectedDynamicSectionSubCategory = ''
    },

    /**
     * Change page event
     * @param page
     */
    changePage(page) {
      if (page != this.request.pagination.page) {
        this.request.pagination.page = page
        this.fetchDynamicSectionSubCategories()
      }
    },
  },
  computed: {
    /**
     * Extract the selected ids
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>
.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}
</style>
